import "./styles/index.css"
import { Route, Switch } from "react-router-dom"
//import { useState, useEffect } from "react"
import TitleComponent from "./components/TitleComponent"
import WhyVelo from "./components/WhyVelo"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import Methodology from "./components/Methodology"
import ReadyBlock from "./components/ReadyBlock"
import Conclusions from "./components/Conclusions"
import FiveSteps from "./components/FiveSteps"

function App() {
  //const [menuInitial, setMenuInitial] = useState(true)
  const menuInitial = true
  const handleMenuChange = (state) => {
    //setMenuInitial(state)
  }

  return (
    <div className="page">
      <Navbar menuInitial={menuInitial} />
      <main className={"main"}>
        <Switch>
          <Route exact path={"/"}>
            <TitleComponent handleMenuState={handleMenuChange} />
            <WhyVelo />
            <ReadyBlock />
            <Conclusions />
            <FiveSteps />
          </Route>

          <Route exact path={"/methodology"}>
            <Methodology />
          </Route>
        </Switch>
      </main>
      <Footer />
    </div>
  )
}

export default App
