import WhyVeloGrid from "./WhyVeloGrid"

export default function WhyVelo() {
  return (
    <section className={"main__section"} id={"why__velo"}>
      <h3
        className={"headline_2 animate__animated toFadein main__element-waits"}
        id={"why-velo"}
      >
        Как велосипедисты помогают городам
      </h3>

      <p
        className={"paragraph_1 animate__animated toFadein main__element-waits"}
      >
        Международные исследования доказывают: если в результате развития
        велоинфраструктуры горожане пересаживаются на&nbsp;велосипеды для
        регулярных поездок, это запускает цепочку положительных эффектов,
        которые касаются здоровья людей, экологии города, развития бизнеса и
        рынка труда.
      </p>

      <WhyVeloGrid />
    </section>
  )
}
