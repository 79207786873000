import { Link } from "react-router-dom"

export default function Methodology() {
  return (
    <section className={"methodology"}>
      <h2 className={"headline_1"}>Методика и данные</h2>
      <ul className={"methodology__list"}>
        <li className={"methodology__list-item"}>
          <p className={"methodology__text"}>
            <span className={"methodology__list-count"}>1</span>
            <b>Рейтинг удобства городов для велосипедистов</b> рассчитан на
            основе среднего балла по трем критериям: «Протяженность и
            расположение велодорожек», «Удобство города для велосипеда» и
            «Безопасность передвижения». Максимальный балл — 10. Оценка по
            критериям проведена через расчет среднего арифметического двух
            показателей. Значения показателей нормируются с учетом наличия
            выбросов: городам присваивается от 1 до 10 баллов по прямой или
            обратной шкале.
          </p>
          <p className={"methodology__text methodology__text_1"}>
            <b>
              Показатели, на основе которых рассчитаны средние значения по
              критериям:
            </b>
          </p>
          <ul className={"methodology__list-second"}>
            <li className={"methodology__list-item"}>
              <p className={"methodology__text methodology__text_0"}>
                Протяженность и расположение велодорожек:
              </p>
              <ol className={"methodology__list-ordered"}>
                <li className={"methodology__text"}>
                  1. Наличие на улицах велодорожек и велополос — доля
                  протяженности велодорожек и велополос в общей протяженности
                  улиц.
                </li>
                <li className={"methodology__text"}>
                  2. Актуальность расположения велодорожек и велополос — доля
                  протяженности велодорожек и велополос, расположенных на
                  улицах, популярных среди пешеходов, автомобилистов и
                  велосипедистов, в общей протяженности велодорожек и велополос.
                </li>
              </ol>
            </li>
            <li className={"methodology__list-item"}>
              <p className={"methodology__text methodology__text_0"}>
                Удобство города для велосипеда:
              </p>
              <ol className={"methodology__list-ordered"}>
                <li className={"methodology__text"}>
                  1. Соотношение маршрутов в объезд и кратчайших возможных
                  маршрутов велосипедистов — доля протяженности участков улиц,
                  на которых велосипедисты вынуждены ехать в объезд наиболее
                  короткого возможного маршрута по тем или иным причинам, в
                  общей протяженности улиц.
                </li>
                <li className={"methodology__text"}>
                  2. Максимальная протяженность маршрута по велодорожкам и
                  велополосам — протяженность максимально возможного
                  веломаршрута по улицам с&nbsp;велодорожками и велополосами,
                  которые не прерываются либо прерываются на&nbsp;минимальное
                  расстояние (например, на перекрестках без велоинфраструктуры).
                </li>
              </ol>
            </li>
            <li className={"methodology__list-item"}>
              <p className={"methodology__text methodology__text_0"}>
                Безопасность передвижения:
              </p>
              <ol className={"methodology__list-ordered"}>
                <li className={"methodology__text"}>
                  1. Количество ДТП с велосипедами и автомобилями на 100 км улиц
                  — количество ДТП, участниками которых одновременно являются
                  автомобилисты и&nbsp;велосипедисты, на 100 км улиц.
                </li>
                <li className={"methodology__text"}>
                  2. Доля ДТП с велосипедами и автомобилями с летальным исходом
                  — доля ДТП, участниками которых одновременно являются
                  автомобилисты и велосипедисты, в&nbsp;которых погиб один из
                  участников столкновения, в общем количестве ДТП, участниками
                  которых одновременно являются автомобилисты и велосипедисты.
                </li>
              </ol>
            </li>
          </ul>
        </li>
        <li className={"methodology__list-item"}>
          <p className={"methodology__text"}>
            <span className={"methodology__list-count"}>2</span>
            <b>Кратчайшие возможные маршруты велосипедистов</b> — маршруты
            велокурьеров от реальных точек выдачи заказов до реальных точек
            доставок при условии, что эти маршруты являются наиболее быстрыми
            (без учета пробок).
          </p>
          <p className={"methodology__list-source"}>
            Источник: Расчет по данным Delivery Club.
          </p>
        </li>

        <li className={"methodology__list-item"}>
          <p className={"methodology__text"}>
            <span className={"methodology__list-count"}>3</span>
            <b>Популярные улицы</b> — это участки улиц, по которым указанные
            участники дорожного движения ездят чаще всего. Каждому участку улицы
            присваивается значение по шкале от 1 до 5, где 1 — минимальная
            интенсивность, 5&nbsp;—&nbsp;максимальная).
          </p>
          <p className={"methodology__text methodology__text_1"}>
            <b>
              Методика расчета для каждой категории участников дорожного
              движения:
            </b>
          </p>
          <ol className={"methodology__list-ordered"}>
            <li className={"methodology__list-item"}>
              <p className={"methodology__text"}>
                a. <b>Популярные улицы среди пешеходов</b> — участки улиц, по
                которым проходит наибольшее количество повседневных маршрутов
                пешеходов: от выхода из дома до мест общественного транспорта
                (остановки, станции метро) и сервисов повседневного
                использования (магазины, кафе, точки услуг и&nbsp;прочее).
              </p>
              <p className={"methodology__list-source"}>
                Источник: Платформа городских данных Urbanmetrics (КБ Стрелка).
              </p>
            </li>
            <li className={"methodology__list-item"}>
              <p className={"methodology__text"}>
                b. <b>Популярные улицы среди автомобилистов</b> — участки улиц,
                по которым проходит наибольшее количество повседневных маршрутов
                автомобилистов: от&nbsp;дома до сервисов повседневного
                использования (магазины, кафе, точки услуг и&nbsp;прочее).
              </p>
              <p className={"methodology__list-source"}>
                Источник: Платформа городских данных Urbanmetrics (КБ Стрелка).
              </p>
            </li>
            <li className={"methodology__list-item"}>
              <p className={"methodology__text"}>
                c. <b>Популярные улицы среди велосипедистов</b> — объединенный
                показатель, который учитывает маршруты велосипедистов и
                велокурьеров:
              </p>
              <ul style={{ listStyle: "none" }}>
                <li className={"methodology__list-item"}>
                  <p className={"methodology__text"}>
                    Количество маршрутов велосипедистов, зафиксированных в
                    Strava.
                  </p>
                  <p className={"methodology__list-source"}>
                    Источник: Расчет по открытым данным Strava.
                  </p>
                </li>
                <li className={"methodology__list-item"}>
                  <p className={"methodology__text"}>
                    Количество маршрутов велокурьеров с июля 2020 года по
                    февраль 2021 года. Учитываются маршруты от точки выдачи
                    заказа до точки доставки.
                  </p>
                  <p className={"methodology__list-source"}>
                    Источник: Расчет по данным Delivery Club.
                  </p>
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li className={"methodology__list-item"}>
          <p className={"methodology__text"}>
            <span className={"methodology__list-count"}>4</span>
            <b>
              Улицы, популярные среди автомобилистов, пешеходов и велосипедистов
            </b>
            &nbsp;— участки улиц, по которым проходит наибольшее количество
            повседневных маршрутов пешеходов, автомобилистов и велосипедистов.
          </p>
          <p className={"methodology__list-source"}>
            Источник: Платформа городских данных Urbanmetrics (КБ Стрелка),
            расчет по открытым данным Delivery Club, Strava.
          </p>
        </li>
        <li className={"methodology__list-item"}>
          <p className={"methodology__text"}>
            <span className={"methodology__list-count"}>5</span>
            <b>Протяженность и расположение велодорожек и велополос.</b> Ввиду
            отсутствия единого источника данные по велодорожкам и велополосам
            собраны из открытых ресурсов и валидированы по OSM и Google Street
            View. Полученный результат может иметь погрешность, которая напрямую
            связана с качеством открытой информации о велосипедной
            инфраструктуре городов-миллионников. В&nbsp;исследовании не делается
            принципиального различия между велодорожками и велополосами. Как
            показывает международный опыт развития велосреды, тип
            велоинфраструктуры необходимо подбирать в зависимости от
            характеристик каждой улицы города — скоростного режима, профиля
            улицы и&nbsp;др. на этапе разработки веломастерплана.
          </p>
          <p className={"methodology__list-source"}>
            Источник: Открытые источники (СМИ, официальные порталы городов,
            сообщества велоактивистов в социальных сетях), OSM, Google Street
            View.
          </p>
        </li>
        <li className={"methodology__list-item"}>
          <p className={"methodology__text"}>
            <span className={"methodology__list-count"}>6</span>
            <b>
              Места, где произошло больше всего ДТП с велосипедистами
              и&nbsp;автомобилями
            </b>{" "}
            — участки улиц, на которых сконцентрированы 2 и более ДТП
            с&nbsp;участием велосипедистов и автомобилистов, произошедших с
            января 2019 года по февраль 2021 года.
          </p>
          <p className={"methodology__list-source"}>
            Источник: Платформа городских данных Urbanmetrics (КБ Стрелка) с
            использованием данных{" "}
            <a
              className={"ready__link"}
              href={"https://xn--90adear.xn--p1ai/"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Госавтоинспекции
            </a>
            .
          </p>
        </li>
      </ul>
      <div className={"methodology__item"}>
        <h3 className={"methodology__subtitle"}>Ссылки в тексте:</h3>

        <ul className={"methodology__list methodology__list_with_numeration"}>
          {/*
            <li className={"methodology__source-item"} id={"methodology-1"}>
              <p className={"methodology__source"}>
                <a
                  className={"methodology__source-link"}
                  href={
                    "https://wciom.ru/analytical-reviews/analiticheskii-obzor/velosipedisty-krutim-pedali"
                  }
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  "Велосипедисты, крутим педали!"
                </a>
                <span className={"methodology__source-text"}>
                  ВЦИОМ, 3 июня 2019 г.
                </span>
              </p>
            </li>
          */}
          <li className={"methodology__source-item"} id={"methodology-2"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://ec.europa.eu/transport/sites/transport/files/cycling-guidance/cycling_and_the_economy.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "Cycling and the economy"
              </a>
              <span className={"methodology__source-text"}>
                Cycling UK, июль 2016 г.
              </span>
            </p>
          </li>

          <li className={"methodology__source-item"} id={"methodology-6"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://www.euro.who.int/__data/assets/pdf_file/0010/352963/Heat.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                Sonja Kahlmeier, Thomas Götschi, Nick Cavill, Alberto Castro
                Fernandez, Christian Brand, David Rojas Rueda, James Woodcock,
                Paul Kelly, Christoph Lieb, Pekka Oja и др., "Health economic
                assessment tool (HEAT) for walking and for cycling"
              </a>
              <span className={"methodology__source-text"}>
                Copenhagen: World Health Organization, Regional Office for
                Europe, 2017 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"} id={"methodology-4"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/509587/value-of-cycling.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                Fiona Rajé and Andrew Saffrey, "The Value of Cycling: Rapid
                Evidence Review of the Economic Benefits of Cycling"
              </a>
              <span className={"methodology__source-text"}>
                Phil Jones Associates & University of Birmingham, 2016 г.{" "}
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"} id={"methodology-5"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://www.mnr.gov.ru/docs/o_sostoyanii_i_ob_okhrane_okruzhayushchey_sredy_rossiyskoy_federatsii/gosudarstvennyy_doklad_o_sostoyanii_i_ob_okhrane_okruzhayushchey_sredy_rossiyskoy_federatsii_v_2018_/"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "О состоянии и об охране окружающей среды Российской Федерации в
                2018 году"
              </a>
              <span className={"methodology__source-text"}>
                Минприроды России, 30 декабря 2019 г.{" "}
              </span>
            </p>
          </li>

          <li className={"methodology__source-item"} id={"methodology-3"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://ecf.com/sites/ecf.com/files/TheBenefitsOfCycling2018.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "The benefits of cycling. Unlocking their potential for Europe"
              </a>
              <span className={"methodology__source-text"}>
                European Cyclists' Federation (ЕСА), 2016
              </span>
            </p>
          </li>

          <li className={"methodology__source-item"} id={"methodology-7"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://content.tfl.gov.uk/walking-cycling-economic-benefits-summary-pack.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "Walking & cycling: The economic benefits"
              </a>
              <span className={"methodology__source-text"}>
                Transport for London (TfL), дата обращения 12 мая 2021 г.
              </span>
            </p>
          </li>
        </ul>
      </div>
      <div className={"methodology__item"}>
        <h3 className={"methodology__subtitle"}>Источники:</h3>
        <ul className={"methodology__list methodology__list_with_numeration"}>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://strelkamag.com/ru/article/na-kakikh-ulicakh-moskvy-poyavyatsya-novye-velodorozhki?utm_source=institute_fb&utm_medium=social&utm_campaign=etim-letom-v-raznyh-rayonah-moskvy-poyavi"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "Новые велодорожки и маршруты велосипедных прогулок в Москве"
              </a>
              <span className={"methodology__source-text"}>
                Strelka Mag, 16 мая 2021 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={"https://letsbikeit.ru/2017/05/bike2work-survey/"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                Служба исследований HeadHunter, "На работу на велосипеде:
                результаты исследования"{" "}
              </a>
              <span className={"methodology__source-text"}>
                Let’s bike it, 17 мая 2017 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://www.forbes.ru/newsroom/biznes/429287-rossiyane-potratili-na-dostavku-20-vseh-rashodov-na-obshchepit-s-nachala-goda"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                Татьяна Романова, "Россияне потратили на доставку 20% всех
                расходов на общепит с начала года"
              </a>
              <span className={"methodology__source-text"}>
                Forbes, 13 мая 2021 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://handshakecycling.eu/sites/default/files/Resource%20Documents/HKI%20Bicycle%20action%20plan%202020-2025%20web_0.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "Bicycle Action Plan 2020–2025"
              </a>
              <span className={"methodology__source-text"}>
                City of Helsinki Urban Environment publications 2020:32, 2020 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://www.eltis.org/sites/default/files/case-studies/documents/copenhagens_cycling_strategy.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "Good, better, best. The City of Copenghagen's bicycle strategy
                2011-2025"
              </a>
              <span className={"methodology__source-text"}>
                The City of Copenhagen, Technical and Environmental
                Administration Traffic Department, 2011 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://www.health.harvard.edu/heart-health/how-noise-pollution-may-harm-the-heart"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "How noise pollution may harm the heart",
              </a>
              <span className={"methodology__source-text"}>
                Harvard Health Publishing, 17 августа 2020 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://www.wwf.org.uk/sites/default/files/2016-12/Finland%20case%20study%20-%20active%20travel.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "International Case Studies for Scotland’s Climate Plan —
                Year-round cycling, Oulu, Finland"
              </a>
              <span className={"methodology__source-text"}>
                WWF, дата обращения 12 мая 2021 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={"https://www.bikesanjose.com/draftplan"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                "San José better bike plan 2025"
              </a>
              <span className={"methodology__source-text"}>
                The Department of Transportation (San Jose) сентябрь 2020 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://www.tomtom.com/en_gb/traffic-index/moscow-traffic/"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                "Traffic Index"{" "}
              </a>
              <span className={"methodology__source-text"}>
                TOMTOM, дата обращения 12 мая 2021 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={"http://www.welivevisionzero.com/vision-zero/"}
                target={"_blank"}
                rel={"noreferrer"}
              >
                "Vision Zero"
              </a>
              <span className={"methodology__source-text"}>
                European Federation of Road Traffic Victims, дата обращения 12
                мая 2021 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://cip-icu.ca/Files/Awards/Plan-Canada/Cycling-Through-Winter"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                Cara Fisher, "Cycling through winter"
              </a>
              <span className={"methodology__source-text"}>
                Plan Canada, №4 (2014): 34–41
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://www.forbes.com/sites/carltonreid/2020/08/18/pop-up-coronavirus-cycleways-deliver-3-billion-in-annual-health-benefits-across-europe/?sh=62c6e3856ad7"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                Carlton Reid, "Pop-Up Coronavirus Cycleways Deliver $3 Billion
                In Annual Health Benefits Across Europe"
              </a>
              <span className={"methodology__source-text"}>
                Forbes, 18 августа 2020 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://www.cyclinguk.org/press-release/2017-02-28/space-cycling-will-improve-peak-hour-traffic-flow-says-cycling-body"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                David Murray, "Space for cycling will improve rush hour traffic
                flow"
              </a>
              <span className={"methodology__source-text"}>
                Cycling UK, 28 февраля 2017 г.
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://ec.europa.eu/transport/sites/transport/files/cycling-guidance/presto_policy_guide_cycling_infrastructure_en.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                Dirk Dufour, "PRESTO Cycling Policy Guide. Cycling
                Infrastructure"
              </a>
              <span className={"methodology__source-text"}>PRESTO, 2010</span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://ec.europa.eu/transport/sites/transport/files/cycling-guidance/presto_policy_guide_promotion_of_cycling_en.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                Dirk Dufour, "PRESTO Cycling Policy Guide. Promotion of Cycling"
              </a>
              <span className={"methodology__source-text"}>PRESTO, 2010</span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://itdpdotorg.wpengine.com/wp-content/uploads/2015/11/A-Global-High-Shift-Cycling-Scenario_Nov-2015.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                Jacob Mason, Lew Fulton, Zane McDonald, "A Global High Shift
                Cycling Scenario: The Potential for Dramatically Increasing.
                Bicycle and E-bike Use in Cities Around the World, with
                Estimated Energy, CO2, and Cost Impacts"
              </a>
              <span className={"methodology__source-text"}>
                Institute for Transportation & Development Policy, 2016
              </span>
            </p>
          </li>
          <li className={"methodology__source-item"}>
            <p className={"methodology__source"}>
              <a
                className={"methodology__source-link"}
                href={
                  "https://ec.europa.eu/transport/sites/transport/files/cycling-guidance/m2020_handbook_en.pdf"
                }
                target={"_blank"}
                rel={"noreferrer"}
              >
                Jutta Deffner, Tomas Hefter, Christian Rudolph, Torben Ziel и
                др., "Handbook on cycling inclusive planning and promotion.
                Capacity development material for the multiplier training within
                the mobile2020 project"
              </a>
              <span className={"methodology__source-text"}>
                Institute for Social-Ecological Research (ISOE) & Hamburg
                University of Technology (TUHH), 2012 г.
              </span>
            </p>
          </li>
        </ul>
      </div>
      <Link className={"methodology__button"} to={"/"}>
        Вернуться на главную
      </Link>
    </section>
  )
}
