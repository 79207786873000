import Icon1 from "../images/why-velo-grid-icons/icon-1.svg"
import Icon2 from "../images/why-velo-grid-icons/icon-2.svg"
import Icon3 from "../images/why-velo-grid-icons/icon-3.svg"
import Icon4 from "../images/why-velo-grid-icons/icon-4.svg"
import Icon5 from "../images/why-velo-grid-icons/icon-5.svg"
import Icon6 from "../images/why-velo-grid-icons/icon-6.svg"
import { HashLink } from "react-router-hash-link"

export default function WhyVeloGrid() {
  return (
    <div className={"why-velo__grid"}>
      <div className={"why-velo__card"}>
        <h3 className={"headline_4"}>Лучше для бюджета</h3>
        <img
          className={"why-velo__card-image"}
          alt={"Лучше для бюджета"}
          src={Icon1}
        />
        <p className={"headline_3"}>13:1</p>
        <p className={"paragraph_2"}>
          соотношение выгоды к затратам при инвестировании в&nbsp;велопешеходную
          инфраструктуру
          <HashLink to={"./methodology#methodology-2"} className={"footnote"}>
            <sup>1</sup>
          </HashLink>
        </p>
      </div>
      <div className={"why-velo__card"}>
        <h3 className={"headline_4"}>Лучше для жизни</h3>
        <img
          className={"why-velo__card-image"}
          alt={"Лучше для жизни"}
          src={Icon5}
        />
        <p className={"headline_3"}>-10%</p>
        <p className={"paragraph_2"}>
          смертность от&nbsp;заболеваний среди людей, которые хотя бы 1,5 часа в
          неделю ездят на велосипеде
          <HashLink to={"./methodology#methodology-3"} className={"footnote"}>
            <sup>2</sup>
          </HashLink>
        </p>
      </div>
      <div className={"why-velo__card"}>
        <h3 className={"headline_4"}>Лучше для бизнеса</h3>
        <img
          className={"why-velo__card-image"}
          alt={"Лучше для бизнеса"}
          src={Icon4}
        />
        <p className={"headline_3"}>+30%</p>
        <p className={"paragraph_2"}>
          к обороту магазинов и кафе на&nbsp;улицах с развитой велопешеходной
          инфраструктурой
          <HashLink to={"./methodology#methodology-4"} className={"footnote"}>
            <sup>3</sup>
          </HashLink>
        </p>
      </div>
      <div className={"why-velo__card"}>
        <h3 className={"headline_4"}>Чище воздух</h3>
        <img
          className={"why-velo__card-image"}
          alt={"Чище воздух"}
          src={Icon3}
        />
        <p className={"headline_3"}>47%</p>
        <p className={"paragraph_2"}>
          вредных веществ в атмосфере — выбросы автотранспорта
          <HashLink to={"./methodology#methodology-5"} className={"footnote"}>
            <sup>4</sup>
          </HashLink>
        </p>
      </div>
      <div className={"why-velo__card"}>
        <h3 className={"headline_4"}>Лучше для туризма</h3>
        <img
          className={"why-velo__card-image"}
          alt={"Лучше для туризма"}
          src={Icon2}
        />
        <p className={"headline_3"}>+20%</p>
        <p className={"paragraph_2"}>
          вклад туристов в экономику города во время поездок на велосипедах
          <HashLink to={"./methodology#methodology-6"} className={"footnote"}>
            <sup>5</sup>
          </HashLink>
        </p>
      </div>
      <div className={"why-velo__card"}>
        <h3 className={"headline_4"}>Лучше для самочувствия</h3>
        <img
          className={"why-velo__card-image"}
          alt={"Лучше для самочувствия"}
          src={Icon6}
        />
        <p className={"headline_3"}>54%</p>
        <p className={"paragraph_2"}>
          людей чувствуют себя более счастливыми и энергичными, когда добираются
          до работы на&nbsp;велосипеде (больше, чем на&nbsp;любых других видах
          транспорта)
          <HashLink to={"./methodology#methodology-7"} className={"footnote"}>
            <sup>6</sup>
          </HashLink>
        </p>
      </div>
    </div>
  )
}
