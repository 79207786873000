import { useState } from "react"
import chelyabinskPdf from '../data/pdfvelo/chelyabinsk.pdf'
import ekaterinburgPdf from '../data/pdfvelo/ekaterinburg.pdf'
import kazanPdf from '../data/pdfvelo/kazan.pdf'
import krasnoyarskPdf from '../data/pdfvelo/krasnoyarsk.pdf'
import moscowPdf from '../data/pdfvelo/moscow.pdf'
import nizniy_novgorodPdf from '../data/pdfvelo/nizniy_novgorod.pdf'
import novosibirskPdf from '../data/pdfvelo/novosibirsk.pdf'
import omskPdf from '../data/pdfvelo/omsk.pdf'
import permPdf from '../data/pdfvelo/perm.pdf'
import rostov_na_donuPdf from '../data/pdfvelo/rostov_na_donu.pdf'
import saint_petersburgPdf from '../data/pdfvelo/saint-petersburg.pdf'
import samaraPdf from '../data/pdfvelo/samara.pdf'
import ufaPdf from '../data/pdfvelo/ufa.pdf'
import volgogradPdf from '../data/pdfvelo/volgograd.pdf'
import voronezhPdf from '../data/pdfvelo/voronezh.pdf'

export default function ReadyButtonComponent() {
  const [buttonClicked, setButtonClicked] = useState(false)
  const clickOnButton = () => {
    setButtonClicked(true)
  }
  return (
    <>
      {buttonClicked ? (
        <>
          <p className={"ready__button-title"}>Выберите город</p>
          <div className={"ready__button-list"}>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                volgogradPdf
              }
            >
              <p className={"ready__button-list-text"}>ВОЛГОГРАД</p>
            </a>

            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                voronezhPdf
              }
            >
              <p className={"ready__button-list-text"}>ВОРОНЕЖ</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                ekaterinburgPdf
              }
            >
              <p className={"ready__button-list-text"}>ЕКАТЕРИНБУРГ</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                kazanPdf
              }
            >
              <p className={"ready__button-list-text"}>КАЗАНЬ</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                krasnoyarskPdf
              }
            >
              <p className={"ready__button-list-text"}>КРАСНОЯРСК</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                moscowPdf
              }
            >
              <p className={"ready__button-list-text"}>МОСКВА</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                nizniy_novgorodPdf
              }
            >
              <p className={"ready__button-list-text"}>НИЖНИЙ НОВГОРОД</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                novosibirskPdf
              }
            >
              <p className={"ready__button-list-text"}>НОВОСИБИРСК</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                omskPdf
              }
            >
              <p className={"ready__button-list-text"}>ОМСК</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                permPdf
              }
            >
              <p className={"ready__button-list-text"}>ПЕРМЬ</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={rostov_na_donuPdf}
            >
              <p className={"ready__button-list-text"}>РОСТОВ-НА-ДОНУ</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                samaraPdf
              }
            >
              <p className={"ready__button-list-text"}>САМАРА</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                saint_petersburgPdf
              }
            >
              <p className={"ready__button-list-text"}>САНКТ-ПЕТЕРБУРГ</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                ufaPdf
              }
            >
              <p className={"ready__button-list-text"}>УФА</p>
            </a>
            <a
              className={"ready__button-list-item"}
              rel="noreferrer"
              target={"_blank"}
              href={
                chelyabinskPdf
              }
            >
              <p className={"ready__button-list-text"}>ЧЕЛЯБИНСК</p>
            </a>
          </div>
        </>
      ) : (
        <button onClick={clickOnButton} className={"ready__button"}>
          Скачать материалы для разработки веломастерплана своего города
        </button>
      )}
    </>
  )
}
