import img1 from "../images/crossroads/1.svg"
import img2 from "../images/crossroads/2.svg"
import img3 from "../images/crossroads/3.svg"
import img4 from "../images/crossroads/4.svg"
import img5 from "../images/crossroads/5.svg"

export default function Crossroads() {
  return (
    <div className={"crossroads__container"}>
      <div className={"crossroads"}>
        <div className={"crossroads-item"}>
          <img className={"crossroads-image"} alt={"Перекрёстки"} src={img1} />
          <div className={"crossroads-info"}>
            <p className={"crossroads-text_accent"}>30% </p>
            <p className={"crossroads-text"}>Перекрестки</p>
          </div>
        </div>
        <div className={"crossroads-item"}>
          <img
            className={"crossroads-image"}
            alt={"Пешеходные переходы"}
            src={img2}
          />
          <div className={"crossroads-info"}>
            <p className={"crossroads-text_accent"}>27% </p>
            <p className={"crossroads-text"}>Пешеходные переходы</p>
          </div>
        </div>
        <div className={"crossroads-item"}>
          <img
            className={"crossroads-image"}
            alt={"Выезд с прилегающей территории"}
            src={img3}
          />
          <div className={"crossroads-info"}>
            <p className={"crossroads-text_accent"}>17% </p>
            <p className={"crossroads-text"}>Выезд с прилегающей территории</p>
          </div>
        </div>
        <div className={"crossroads-item"}>
          <img
            className={"crossroads-image"}
            alt={"Внутридворовая территория"}
            src={img4}
          />
          <div className={"crossroads-info"}>
            <p className={"crossroads-text_accent"}>12% </p>
            <p className={"crossroads-text"}>Внутридворовая территория</p>
          </div>
        </div>
        <div className={"crossroads-item"}>
          <img
            className={"crossroads-image"}
            alt={"Тротуар, пешеходная дорожка, остановка транспорта"}
            src={img5}
          />
          <div className={"crossroads-info"}>
            <p className={"crossroads-text_accent"}>2% </p>
            <p className={"crossroads-text"}>
              Тротуар, пешеходная дорожка, остановка транспорта
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
