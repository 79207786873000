import VoronezhPopularity from "../images/citiesData/voronezh/popularity.jpg"
import VoronezhSafety from "../images/citiesData/voronezh/safety.jpg"
import VoronezhRelevance from "../images/citiesData/voronezh/relevance.jpg"
import VoronezhDelivery from "../images/citiesData/voronezh/delivery.jpg"

import VolgogradSafety from "../images/citiesData/volgograd/safety.jpg"
import VolgogradRelevance from "../images/citiesData/volgograd/relevance.jpg"
import VolgogradPopularity from "../images/citiesData/volgograd/popularity.jpg"
import VolgogradDelivery from "../images/citiesData/volgograd/delivery.jpg"

import ChelyabinskSafety from "../images/citiesData/chelyabinsk/safety.jpg"
import ChelyabinskPopularity from "../images/citiesData/chelyabinsk/popularity.jpg"
import ChelyabinskRelevance from "../images/citiesData/chelyabinsk/relevance.jpg"
import ChelyabinskDelivery from "../images/citiesData/chelyabinsk/delivery.jpg"

import KazanSafety from "../images/citiesData/kazan/safety.jpg"
import KazanPopularity from "../images/citiesData/kazan/popularity.jpg"
import KazanRelevance from "../images/citiesData/kazan/relevance.jpg"
import KazanDelivery from "../images/citiesData/kazan/delivery.jpg"

import KrasnoyarskSafety from "../images/citiesData/krasnoyarsk/safety.jpg"
import KrasnoyarskPopularity from "../images/citiesData/krasnoyarsk/popularity.jpg"
import KrasnoyarskRelevance from "../images/citiesData/krasnoyarsk/relevance.jpg"
import KrasnoyarskDelivery from "../images/citiesData/krasnoyarsk/delivery.jpg"

import MoscowSafety from "../images/citiesData/moscow/safety.jpg"
import MoscowPopularity from "../images/citiesData/moscow/popularity.jpg"
import MoscowRelevance from "../images/citiesData/moscow/relevance.jpg"
import MoscowDelivery from "../images/citiesData/moscow/delivery.jpg"

import NizhniyNovgorodSafety from "../images/citiesData/nizhny_novgorod/safety.jpg"
import NizhniyNovgorodPopularity from "../images/citiesData/nizhny_novgorod/popularity.jpg"
import NizhniyNovgorodRelevance from "../images/citiesData/nizhny_novgorod/relevance.jpg"
import NizhniyNovgorodDelivery from "../images/citiesData/nizhny_novgorod/delivery.jpg"

import NovosibirskSafety from "../images/citiesData/novosibirsk/safety.jpg"
import NovosibirskPopularity from "../images/citiesData/novosibirsk/popularity.jpg"
import NovosibirskRelevance from "../images/citiesData/novosibirsk/relevance.jpg"
import NovosibirskDelivery from "../images/citiesData/novosibirsk/delivery.jpg"

import OmskSafety from "../images/citiesData/omsk/safety.jpg"
import OmskPopularity from "../images/citiesData/omsk/popularity.jpg"
import OmskRelevance from "../images/citiesData/omsk/relevance.jpg"
import OmskDelivery from "../images/citiesData/omsk/delivery.jpg"

import PermSafety from "../images/citiesData/perm/safety.jpg"
import PermPopularity from "../images/citiesData/perm/popularity.jpg"
import PermRelevance from "../images/citiesData/perm/relevance.jpg"
import PermDelivery from "../images/citiesData/perm/delivery.jpg"

import RostovSafety from "../images/citiesData/rostov/safety.jpg"
import RostovPopularity from "../images/citiesData/rostov/popularity.jpg"
import RostovRelevance from "../images/citiesData/rostov/relevance.jpg"
import RostovDelivery from "../images/citiesData/rostov/delivery.jpg"

import SaintPetersburgSafety from "../images/citiesData/saint_petersburg/safety.jpg"
import SaintPetersburgPopularity from "../images/citiesData/saint_petersburg/popularity.jpg"
import SaintPetersburgRelevance from "../images/citiesData/saint_petersburg/relevance.jpg"
import SaintPetersburgDelivery from "../images/citiesData/saint_petersburg/delivery.jpg"

import SamaraSafety from "../images/citiesData/samara/safety.jpg"
import SamaraPopularity from "../images/citiesData/samara/popularity.jpg"
import SamaraRelevance from "../images/citiesData/samara/relevance.jpg"
import SamaraDelivery from "../images/citiesData/samara/delivery.jpg"

import UfaSafety from "../images/citiesData/ufa/safety.jpg"
import UfaPopularity from "../images/citiesData/ufa/popularity.jpg"
import UfaRelevance from "../images/citiesData/ufa/relevance.jpg"
import UfaDelivery from "../images/citiesData/ufa/delivery.jpg"

import YekaterinburgSafety from "../images/citiesData/yekaterinburg/safety.jpg"
import YekaterinburgPopularity from "../images/citiesData/yekaterinburg/popularity.jpg"
import YekaterinburgRelevance from "../images/citiesData/yekaterinburg/relevance.jpg"
import YekaterinburgDelivery from "../images/citiesData/yekaterinburg/delivery.jpg"

export const citiesData = [
  {
    title: "Уфа",
    popularity: UfaPopularity,
    safety: UfaSafety,
    relevance: UfaRelevance,
    delivery: UfaDelivery,
    id: 1,
    desc:
      "Один из лидеров по доле велоинфраструктуры в улично-дорожной сети. " +
      "Большая часть дорожек расположена на популярных улицах с повышенным спросом среди велосипедистов. " +
      "Высокий потенциал для создания масштабной сети велодорожек через объединение существующих участков.",
    resultScore: 7.8,
    relevanceScore: 9.0,
    convenienceScore: 7.5,
    safetyScore: 7.0,
  },
  {
    title: "Пермь",
    popularity: PermPopularity,
    safety: PermSafety,
    relevance: PermRelevance,
    delivery: PermDelivery,
    id: 2,
    desc:
      "Велоинфраструктура находится на улицах с повышенной интенсивностью " +
      "перемещений всех участников движения — за счет этого, а также за счет удобства " +
      "дорожной сети для велосипедистов город занимает высокие позиции в рейтинге. " +
      "Проблемная зона — места концентрации ДТП с повышенным травматизмом велосипедистов.\n",
    resultScore: 6.8,
    relevanceScore: 7.5,
    convenienceScore: 6.5,
    safetyScore: 6.5,
  },
  {
    title: "Волгоград",
    popularity: VolgogradPopularity,
    safety: VolgogradSafety,
    relevance: VolgogradRelevance,
    delivery: VolgogradDelivery,
    id: 3,
    desc:
      "Один из наиболее безопасных городов для велосипедистов. " +
      "Фактические веломаршруты часто совпадают с кратчайшими, что говорит " +
      "об относительном удобстве улично-дорожной сети. Слабое место — низкая " +
      "общая протяженность дорожек и полос.\n",
    resultScore: 6.7,
    relevanceScore: 5.0,
    convenienceScore: 5.0,
    safetyScore: 10,
  },
  {
    title: "Екатеринбург",
    popularity: YekaterinburgPopularity,
    safety: YekaterinburgSafety,
    relevance: YekaterinburgRelevance,
    delivery: YekaterinburgDelivery,
    id: 4,
    desc:
      "Относительно высокий уровень обеспеченности велоинфраструктурой. При этом " +
      "большая часть дорожек расположена за пределами участков повышенного спроса " +
      "и сконцентрирована, как следствие, в наиболее удобном для велосипедистов районе — Академическом.\n",
    resultScore: 6.7,
    relevanceScore: 6,
    convenienceScore: 7,
    safetyScore: 7,
  },
  {
    title: "Самара",
    popularity: SamaraPopularity,
    safety: SamaraSafety,
    relevance: SamaraRelevance,
    delivery: SamaraDelivery,
    id: 5,
    desc:
      "Город достаточно безопасен для велосипедистов и имеет высокий потенциал " +
      "для создания сети велодорожек через объединения существующих участков. Кратчайшие " +
      "и фактические маршруты велокурьеров часто не совпадают, что говорит о необходимости " +
      "создания новой инфраструктуры и развития существующей.\n",
    resultScore: 6.3,
    relevanceScore: 6.5,
    convenienceScore: 5,
    safetyScore: 7.5,
  },
  {
    title: "Красноярск",
    popularity: KrasnoyarskPopularity,
    safety: KrasnoyarskSafety,
    relevance: KrasnoyarskRelevance,
    delivery: KrasnoyarskDelivery,
    id: 6,
    desc:
      "Высокий уровень безопасности для велосипедистов подкрепляется удобством " +
      "улично-дорожной сети для перемещений на велосипеде. При этом в городе небольшая " +
      "общая протяженность дорожек и полос, которые чаще всего расположены за пределами " +
      "участков повышенного спроса.\n",
    resultScore: 6.2,
    relevanceScore: 2,
    convenienceScore: 6.5,
    safetyScore: 10,
  },
  {
    title: "Москва",
    popularity: MoscowPopularity,
    safety: MoscowSafety,
    relevance: MoscowRelevance,
    delivery: MoscowDelivery,
    id: 7,
    desc:
      "Город с отрывом лидирует в протяженности дорожек и полос, при этом " +
      "их основная часть расположена за пределами участков повышенного спроса. Велосипедисты " +
      "в Москве чаще, чем в других городах, вынуждены прокладывать маршруты в объезд неудобных " +
      "и опасных участков. Летом 2021 года в городе планируют обустроить временные велодорожки, " +
      "изучить статистику и принять решение об организации постоянных полос.\n",
    resultScore: 5.7,
    relevanceScore: 5.5,
    convenienceScore: 5,
    safetyScore: 6.5,
  },
  {
    title: "Челябинск",
    popularity: ChelyabinskPopularity,
    safety: ChelyabinskSafety,
    relevance: ChelyabinskRelevance,
    delivery: ChelyabinskDelivery,
    id: 8,
    desc:
      "Велоинфраструктура расположена за пределами участков повышенного спроса " +
      "и по протяженности уступает другим городам-миллионникам. Среди положительных " +
      "показателей — нулевой уровень смертности в ДТП с участием велосипедистов " +
      "за рассматриваемый период.\n",
    resultScore: 5.7,
    relevanceScore: 2.5,
    convenienceScore: 5,
    safetyScore: 9.5,
  },
  {
    title: "Санкт-Петербург",
    popularity: SaintPetersburgPopularity,
    safety: SaintPetersburgSafety,
    relevance: SaintPetersburgRelevance,
    delivery: SaintPetersburgDelivery,
    id: 9,
    desc:
      "Город с самыми высокими баллами за удобство улиц для велосипедистов " +
      "и связность велодорожек и велополос, при этом основная велоинфраструктура " +
      "расположена за пределами участков с интенсивным дорожным движением. Наблюдается " +
      "высокий уровень аварийности с участием велосипедистов и смертности в таких происшествиях.\n",
    resultScore: 5.3,
    relevanceScore: 3.5,
    convenienceScore: 9.5,
    safetyScore: 3,
  },
  {
    title: "Казань",
    popularity: KazanPopularity,
    safety: KazanSafety,
    relevance: KazanRelevance,
    delivery: KazanDelivery,
    id: 10,
    desc:
      "Город с низкой протяженностью велоинфраструктуры, большая часть которой " +
      "расположена за пределами участков повышенного спроса. Улицы удобны (фактические " +
      "маршруты часто совпадают с кратчайшими) и достаточно безопасны для велосипедистов, " +
      "однако здесь чаще, чем в других городах-миллионниках, происходят ДТП на выездах " +
      "из дворовых территорий.\n",
    resultScore: 5.2,
    relevanceScore: 3,
    convenienceScore: 6,
    safetyScore: 6.5,
  },
  {
    title: "Ростов-на-Дону",
    popularity: RostovPopularity,
    safety: RostovSafety,
    relevance: RostovRelevance,
    delivery: RostovDelivery,
    id: 11,
    desc:
      "Средний уровень обеспеченности велоинфраструктурой сочетается с относительно " +
      "высоким удобством улиц для велосипеда. Самая высокая доля ДТП с велосипедистами " +
      "со смертельным исходом среди всех городов рейтинга.\n",
    resultScore: 4.8,
    relevanceScore: 4.5,
    convenienceScore: 5,
    safetyScore: 5,
  },
  {
    title: "Новосибирск",
    popularity: NovosibirskPopularity,
    safety: NovosibirskSafety,
    relevance: NovosibirskRelevance,
    delivery: NovosibirskDelivery,
    id: 12,
    desc:
      "Один из самых низких показателей аварийности среди велосипедистов, " +
      "но уровень смертности в таких авариях — высокий. Низкий балл за обеспеченность, " +
      "связность и расположение велоинфраструктуры компенсируется относительно высоким " +
      "уровнем удобства улиц для велоперемещений в целом.\n",
    resultScore: 4.7,
    relevanceScore: 2.5,
    convenienceScore: 5,
    safetyScore: 6.5,
  },
  {
    title: "Омск",
    popularity: OmskPopularity,
    safety: OmskSafety,
    relevance: OmskRelevance,
    delivery: OmskDelivery,
    id: 13,
    desc:
      "Город проигрывает большинству участников рейтинга по обеспеченности " +
      "велоинфраструктурой, несмотря на протяженную велодорожку вдоль набережной. К тому же " +
      "вся велоинфраструктура города расположена за пределами участков повышенного спроса.\n",
    resultScore: 4.5,
    relevanceScore: 1.5,
    convenienceScore: 6.5,
    safetyScore: 5.5,
  },
  {
    title: "Воронеж",
    popularity: VoronezhPopularity,
    safety: VoronezhSafety,
    relevance: VoronezhRelevance,
    delivery: VoronezhDelivery,
    id: 14,
    desc:
      "Низкий показатель обеспеченности велоинфраструктурой и связности велодорожек " +
      "и велополос. Улично-дорожная сеть города позволяет добраться до точки назначения наиболее " +
      "короткими маршрутами. Небольшое количество ДТП с велосипедистами нивелируется высоким уровнем " +
      "смертности в таких авариях.\n",
    resultScore: 4,
    relevanceScore: 3,
    convenienceScore: 4.5,
    safetyScore: 4.5,
  },
  {
    title: "Нижний Новгород",
    popularity: NizhniyNovgorodPopularity,
    safety: NizhniyNovgorodSafety,
    relevance: NizhniyNovgorodRelevance,
    delivery: NizhniyNovgorodDelivery,
    id: 15,
    desc:
      "Низкий уровень обеспеченности велоинфраструктурой и развития городской велокультуры, " +
      "но с потенциалом для создания сети веломаршрутов через объединение разрозненных участков. " +
      "Высокий уровень аварийности на дорогах с участием велосипедистов.\n",
    resultScore: 4,
    relevanceScore: 2.5,
    convenienceScore: 4,
    safetyScore: 5.5,
  },
]
