import ImageCity from "../images/title/titleImage.png"
import { useState, useEffect } from "react"

export default function TitleComponent({ handleMenuState }) {
  const [toObserve, setToObserve] = useState(undefined)

  useEffect(() => {
    setToObserve(document.querySelector("#title_container"))
  }, [])

  useEffect(() => {
    const observerCallback = (changes, observer) => {
      changes.forEach((c) => {
        if (c.intersectionRatio > 0) {
          handleMenuState(true)
        } else {
          handleMenuState(false)
        }
      })
    }
    const observer = new IntersectionObserver(observerCallback)

    if (toObserve) {
      observer.observe(toObserve)
    }
  }, [handleMenuState, toObserve])

  return (
    <section className={"title"} id={"title"}>
      <div className={"title__container"} id={"title_container"}>
        <h1 className={"headline_1"}>ТРАНСПОРТ БУДУЩЕГО:</h1>

        <h2 className={"headline_3"}>
          как сделать города удобнее для велосипедистов
        </h2>
        <img
          className={"title__image"}
          src={ImageCity}
          alt={"Транспорт будущего"}
        />
      </div>
      <div className={"main__section"}>
        <p className={"paragraph_1"}>
          Большое исследование Delivery Club и КБ Стрелка охватывает 15
          российских городов-миллионников и определяет главные барьеры и
          возможности при развитии их велоинфраструктуры. Специалисты
          проанализировали пространственные данные о перемещениях разных
          участников велодвижения, в том числе 2,7 млн поездок курьеров, что
          дало возможность оценить то, насколько города России удобны для
          поездок на велосипеде не только во время прогулок и занятий спортом,
          но и в повседневной жизни.
          <br />
          <br />В отчете вы найдете рейтинг удобства городов для велосипедистов,
          главные выводы исследования и чек-лист с подробными данными, которые
          помогут определить, в каких местах вашего города велоинфраструктура
          нужна в первую очередь.
        </p>
      </div>
    </section>
  )
}
