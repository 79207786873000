import FiveStepsImage from "../images/fiveSteps/fiveStepsImage.png"
import Step1Img from "../images/fiveSteps/steps/step1.svg"
import Step2Img from "../images/fiveSteps/steps/step2.svg"
import Step3Img from "../images/fiveSteps/steps/step3.svg"
import Step4Img from "../images/fiveSteps/steps/step4.svg"
import Step5Img from "../images/fiveSteps/steps/step5.svg"
import Step1Vid from '../images/stepsVideos/step1.mp4'
import Step2Vid from '../images/stepsVideos/step2.mp4'
import Step3Vid from '../images/stepsVideos/step3.mp4'
import Step4Vid from '../images/stepsVideos/step4.mp4'
import Step5Vid from '../images/stepsVideos/step5.mp4'
import ReadyButtonComponent from "./ReadyButtonComponent"

export default function FiveSteps() {
  const SingleStep = ({ number, title, text, side, imgSrc, vidSrc }) => {
    return (
      <div
        className={`step ${side === "left" ? "" : "step_reverse"}`}
        style={number === "1" ? { marginBottom: 0 } : {}}
      >
        <div className={`step__container`}>
          <h3 className={"headline_4"}>
            <span className={"paragraph__accent"}>{number}</span>
            {title}
          </h3>
          <ul className={"step__list"}>
            {text.map((t, i) => {
              return (
                <li className={`paragraph_2 `} key={`step_text_${number}-${i}`}>
                  {t}
                </li>
              )
            })}
          </ul>
        </div>
        <video
          className={`step__image ${
            side === "left" ? "" : "step__image_reverse"
          }`}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={vidSrc} />
          <img
            className={`step__image ${
              side === "left" ? "" : "step__image_reverse"
            }`}
            alt={"Велосипедист :)"}
            src={imgSrc}
          />
        </video>
        <img
          className={`step__image step__image_mobile`}
          alt={"Велосипедист :)"}
          src={imgSrc}
        />
      </div>
    )
  }
  return (
    <section className={"main__section"} id={"advices"}>
      <img
        style={{ margin: 0, maxWidth: "100%" }}
        className={"step__advice-image"}
        alt={"Пять шагов для развития городской велосистемы"}
        src={FiveStepsImage}
      />
      <h2 className={"headline_2"} style={{ marginBottom: 24 }}>
        Как развивать велоинфраструк&shy;туру своего города
      </h2>
      <p
        className={"paragraph_2 advices-paragraph"}
        style={{
          marginBottom: 48,
          textAlign: "start",
          maxWidth: 980,
        }}
      >
        Чек-лист для городских управленцев и велоактивистов
      </p>
      <SingleStep
        number={"1"}
        title={
          "Оценить ситуацию с помощью исследования Delivery Club и КБ Стрелка"
        }
        text={[
          "Обсудите исследование и определите основные задачи вместе с жителями, экспертами и предпринимателями",
          "Проверьте, соответствуют ли актуальным запросам существующие программы благоустройства, развития улично-дорожной сети и велоинфраструктуры",
        ]}
        side={"left"}
        imgSrc={Step1Img}
        vidSrc={
          Step1Vid
          //"https://static.strelka-kb.com/delivery_velofuture/video/step1.mp4"
        }
      />
      <div className={"step__button-container"}>
        <ReadyButtonComponent />
      </div>
      <SingleStep
        number={"2"}
        title={
          "Разработать веломастерплан вместе с жителями, экспертами и предпринимателями"
        }
        text={[
          "Определите масштаб мастер-плана: от одного-двух районов до целого города",
          "Найдите самые востребованные среди автомобилистов, пешеходов и велосипедистов улицы и свяжите их с существующей велоинфраструктурой",
          "Придумайте решения для наиболее опасных участков — перекрестков и пешеходных переходов",
          "Убедитесь, что вдоль велоинфраструктуры достаточно освещения",
          "Определите места для остановок, отдыха, ремонта велосипедов",
          "Разработайте дорожную карту для внедрения велоинфраструктуры в существующие или новые городские программы",
        ]}
        side={"right"}
        imgSrc={Step2Img}
        vidSrc={
          Step2Vid
          //"https://static.strelka-kb.com/delivery_velofuture/video/step2.mp4"
        }
      />
      <SingleStep
        number={"3"}
        title={"Сделать тестовую инфраструктуру на один сезон"}
        text={[
          "Нанесите тестовую разметку в соответствии с вашим веломастерпланом",
          "Понаблюдайте, как тестовые велополосы и велодорожки используются в разное время года",
        ]}
        side={"left"}
        imgSrc={Step4Img}
        vidSrc={
        Step4Vid
          //"https://static.strelka-kb.com/delivery_velofuture/video/step4.mp4"
        }
      />
      <SingleStep
        number={"4"}
        title={"Организовать прием обратной связи от велосипедистов "}
        text={[
          "Сделайте сайт или приложение для краудсорсинга идей",
          "Запустите чат-бота или телеграм-канал для удобного общения ",
          "Создайте рабочий адрес электронной почты или телефонную горячую линию ",
        ]}
        side={"right"}
        imgSrc={Step5Img}
        vidSrc={
          Step5Vid
          //"https://static.strelka-kb.com/delivery_velofuture/video/step5.mp4"
        }
      />
      <SingleStep
        number={"5"}
        title={"Оценить результаты и начать реализацию мастер-плана"}
        text={[
          "Проанализируйте эффекты тестовой велоинфраструктуры и отзывы пользователей",
          "Внесите в план корректировки и утвердите этапы его реализации с учетом планов развития города",
          "Создавайте велоинфраструктуру в своем городе и следите за изменениями к лучшему",
        ]}
        side={"left"}
        imgSrc={Step3Img}
        vidSrc={
          Step3Vid
          //"https://static.strelka-kb.com/delivery_velofuture/video/step3.mp4"
        }
      />
    </section>
  )
}
