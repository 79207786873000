import MapComponent from "./MapComponent"
import CyclistsChoose from "./CyclistsChoose"
import Crossroads from "./Crossroads"

export default function Conclusions() {
  return (
    <section className={"conclusions"} id={"conclusions"}>
      <h2 className={"headline_2"}>
        4 главных вывода
        <br /> исследования
      </h2>
      <h3 className={"headline_3"}>
        <span className={"paragraph__accent"}>1</span>
        Велосипед стал транспортом для&nbsp;повседневных перемещений
        по&nbsp;всему&nbsp;городу
      </h3>
      <p className={"paragraph_1 conclusions__paragraph"}>
        С каждым годом велосипед из элемента досуга и спорта превращается
        в&nbsp;транспорт для ежедневных перемещений и охватывает все больше
        городского пространства. Сегодня в российских городах-миллионниках люди
        активно передвигаются на велосипеде по 70% улиц. Появляются новые
        локальные сети маршрутов внутри районов и между районами, где велосипед
        используется не&nbsp;только для прогулок по паркам и набережным без
        определенной точки назначения, но и для рутинных поездок на работу или
        по делам из точки&nbsp;А в&nbsp;точку&nbsp;Б.
      </p>
      <MapComponent typeOfMap={"popularity"} />
      <h3 className={"headline_3"}>
        <span className={"paragraph__accent"}>2</span>
        Велоинфраструктура располагается не&nbsp;там, где это нужно всем
        участникам движения
      </h3>
      <p className={"paragraph_1 conclusions__paragraph"}>
        Велоинфраструктура крупных российских городов находится не там, где она
        нужна больше всего: 88% дорожек и полос расположены за пределами улиц,
        популярных среди велосипедистов, автомобилистов и пешеходов. Из-за этого
        велосипедисты часто вынуждены ездить по тротуарам или автодорогам,
        подвергая себя и других опасности.
      </p>
      <MapComponent typeOfMap={"relevance"} />
      <h3 className={"headline_3"}>
        <span className={"paragraph__accent"}>3</span>
        Большинство ДТП с&nbsp;велосипедами и&nbsp;автомобилями происходят
        на&nbsp;популярных улицах и рядом с ними
      </h3>
      <p className={"paragraph_1 conclusions__paragraph"}>
        Появление велосипедистов на популярных улицах без велоинфраструктуры
        неизбежно приводит к конфликтам и происшествиям: выезжать на дорогу
        опасно для самих велосипедистов, выезжать на тротуар — еще и для
        пешеходов. В&nbsp;среднем на популярных улицах с активными потоками
        транспорта и пешеходов происходит в 2 раза больше ДТП с велосипедистами,
        чем на всех остальных улицах города. Именно в этих местах больше всего
        нужны разведение потоков и&nbsp;комфортная инфраструктура для всех
        участников движения.
      </p>
      <MapComponent typeOfMap={"safety"} />
      <h3 className={"headline_3"}>
        <span className={"paragraph__accent"}>4</span>
        Места пересечения с другими участниками движения представляют наибольшую
        опасность для&nbsp;велосипедистов
      </h3>
      <p className={"paragraph_1 conclusions__paragraph"}>
        Самые опасные для велосипедистов места — перекрестки и пешеходные
        переходы, точки пересечения с прохожими и автомобилями. На них
        приходится 45% всех ДТП с велосипедистами (часть происшествий происходит
        на пешеходных переходах, расположенных на перекрестках). При этом
        инциденты происходят не только во время езды, но и в точках начала и
        окончания поездки, в&nbsp;пространствах с низкой скоростью передвижения.
        На них приходится больше 30% случаев.
      </p>
      <h4 className={"headline_4"} style={{ marginTop: 72 }}>
        Участки, на которых чаще всего происходят ДТП с велосипедами
        и&nbsp;автомобилями
      </h4>
      <Crossroads />
      <p className={"paragraph_1 conclusions__paragraph"}>
        Велосипедисты предпочитают объезжать опасные участки с интенсивным
        автомобильным движением и недружелюбной средой через парки, бульвары,
        набережные и другие пешеходные пространства. Стихийное пересечение
        потоков создает дискомфорт, конфликты и столкновения пешеходов и
        велосипедистов, поэтому таким пространствам критически нужна
        велоинфраструктура.
      </p>
      <CyclistsChoose />

      {/*
      <h3 className={"headline_3"}>
        <span className={"paragraph__accent"}>5</span>С развитием велодоставки
        появились новые сценарии использования общественных пространств
      </h3>
      <p className={"paragraph_1 conclusions__paragraph"}>
      В России активно благоустраивают общественные пространства. С развитием
      городской среды и новых форм мобильности меняются и сценарии их использования.
      В 2/3 всех общественных пространств в городах-миллионниках привезли хотя бы
      один заказ с помощью велодоставки.
      </p>
      <MapComponent typeOfMap={"delivery"} />
      */}
    </section>
  )
}
