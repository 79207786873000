import FbIcon from "../images/icons/share/fb.svg"
import TwitterIcon from "../images/icons/share/twitter.svg"
import VkIcon from "../images/icons/share/vk.svg"
import TgIcon from "../images/icons/share/tg.svg"

export function FbShareButton() {
  return (
    <>
      <script
        async
        defer
        crossOrigin="anonymous"
        src="https://connect.facebook.net/ru_RU/sdk.js#xfbml=1&version=v11.0"
        nonce="vDzMMTGF"
      />

      <div
        className="fb-share-button"
        data-href="https://velofuture.strelka-kb.com/"
        data-layout="button"
        data-size="small"
      >
        <a
          target="_blank"
          rel={"noreferrer"}
          href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fvelofuture.strelka-kb.com%2F&amp;src=sdkpreparse"
          className="fb-xfbml-parse-ignore"
        >
          <img
            className={"share__button"}
            src={FbIcon}
            alt={"Поделиться в Facebook"}
          />
        </a>
      </div>
    </>
  )
}

export function VkShareButton() {
  return (
    <a
      href="https://vk.com/share.php?url=https://velofuture.strelka-kb.com/"
      target="_blank"
      rel={"noreferrer"}
    >
      <img
        className={"share__button"}
        src={VkIcon}
        alt={"Поделиться в VK"}
        style={{
          maxWidth: "unset",
          maxHeight: "unset",
          width: 30,
          height: 30,
        }}
      />
    </a>
  )
}

export function TwitterShareButton() {
  return (
    <>
      <a
        href="https://twitter.com/share?ref_src=twsrc%5Etfw"
        className="twitter-share-button"
        data-url="https://velofuture.strelka-kb.com/"
        data-show-count="false"
      >
        <img
          className={"share__button"}
          src={TwitterIcon}
          alt={"Поделиться в Twitter"}
        />
      </a>
      <script
        async
        src="https://platform.twitter.com/widgets.js"
        charSet="utf-8"
      />
    </>
  )
}

export function TelegramShareButton() {
  return (
    <a
      className={""}
      href={"https://t.me/share/url?url=https://velofuture.strelka-kb.com/"}
      target={"_blank"}
      rel={"noreferrer"}
    >
      <img className={"share__button"} alt={""} src={TgIcon} />
    </a>
  )
}
