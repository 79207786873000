import { useState, useEffect } from "react"
import { citiesData } from "../data/citiesData"
import legend1 from "../images/legends/legend-1.svg"
import legend2 from "../images/legends/legend-2.svg"
import legend3 from "../images/legends/legend-3.svg"
import legend4 from "../images/legends/legend-4.svg"
import Spinner from "../images/icons/spinner.svg"
import DeliveryLogo from "../images/logos/Delivery_logo.svg"
import StrelkaLogo from "../images/logos/kb-logo.svg"

export default function MapComponent({ typeOfMap }) {
  const [currentCity, setCurrentCity] = useState({})
  const [loading, setLoading] = useState(true)
  const [selectValue, setSelectValue] = useState(undefined)
  const [currentImage, setCurrentImage] = useState("")

  const cityEx =
    typeOfMap === "relevance"
      ? "Пермь"
      : typeOfMap === "popularity"
      ? "Москва"
      : typeOfMap === "safety"
      ? "Красноярск"
      : typeOfMap === "delivery"
      ? "Москва"
      : undefined

  const citiesSorted = citiesData.sort((el1, el2) => {
    if (el1.title > el2.title) {
      return 1
    }
    if (el2.title > el1.title) {
      return -1
    }
    return 0
  })

  const topCity = citiesSorted.find((el) => {
    return el.title === cityEx
  })

  const currentLegend =
    typeOfMap === "popularity"
      ? legend1
      : typeOfMap === "relevance"
      ? legend2
      : typeOfMap === "safety"
      ? legend3
      : typeOfMap === "delivery"
      ? legend4
      : undefined

  useEffect(() => {
    if (topCity) {
      setCurrentCity(topCity)
    }
  }, [topCity])

  const handleCitySelection = (evt) => {
    setSelectValue(evt.target.value)
  }

  useEffect(() => {
    if (currentCity) {
      setLoading(true)
      const img = new Image()
      if (typeOfMap === "popularity") {
        img.src = currentCity.popularity
        img.onload = () => {
          setLoading(false)
          setCurrentImage(currentCity.popularity)
        }
      }
      if (typeOfMap === "relevance") {
        img.src = currentCity.relevance
        img.onload = () => {
          setLoading(false)
          setCurrentImage(currentCity.relevance)
        }
      }
      if (typeOfMap === "safety") {
        img.src = currentCity.safety
        img.onload = () => {
          setLoading(false)
          setCurrentImage(currentCity.safety)
        }
      }

      if (typeOfMap === "delivery") {
        img.src = currentCity.delivery
        img.onload = () => {
          setLoading(false)
          setCurrentImage(currentCity.delivery)
        }
      }
    }
  }, [typeOfMap, currentCity])

  useEffect(() => {
    if (selectValue) {
      const selectedCity = citiesData.find((el) => {
        return el.title === selectValue
      })
      setCurrentCity(selectedCity)
    }
  }, [selectValue])

  return (
    <section
      className={`map-component`}
      style={{
        backgroundImage: `url(${loading ? "" : currentImage})`,
      }}
    >
      <div className={"map-component__container"}>
        <div className={"map-component__header"}>
          <h3 className={"headline_4 map-component__headline"}>
            <span>
              {typeOfMap === "popularity"
                ? "Популярность улиц среди велосипедистов"
                : typeOfMap === "relevance"
                ? "Популярные улицы среди всех участников уличного движения и улицы с велоинфраструктурой"
                : typeOfMap === "safety"
                ? "Популярные улицы и места, где произошло больше всего ДТП с велосипедистами и автомобилями"
                : typeOfMap === "delivery"
                ? "Точки велодоставки еды в границах общественных пространств"
                : ""}
            </span>
          </h3>
          <div className={"map-component__select-wrapper"}>
            <select
              name={`${typeOfMap}-select`}
              value={selectValue || ""}
              onChange={handleCitySelection}
              className={"map-component__select"}
            >
              {topCity ? (
                <option
                  value={topCity.title}
                  key={`select-${typeOfMap}-${topCity.title}`}
                >
                  {topCity.title}
                </option>
              ) : (
                <></>
              )}
              {citiesSorted
                .filter((el) => {
                  return el.title !== cityEx
                })
                .map((el) => {
                  return (
                    <option
                      value={el.title}
                      key={`select-${typeOfMap}-${el.title}`}
                    >
                      {el.title}
                    </option>
                  )
                })}
            </select>
          </div>
          <p className={"map-component__tip"}>
            <span
              style={{
                backgroundColor: "#121212",
                boxShadow: "10px 0 0px 0px #121212, -10px 0 0px 0px #121212",
              }}
            >
              {" "}
              Первым показывается город с наилучшей ситуацией
            </span>
          </p>
        </div>
        {loading ? (
          <img
            className={"map-component__spinner"}
            alt={"Spinner"}
            src={Spinner}
          />
        ) : (
          <></>
        )}
        <div className={"map-component__logos-and-legend"}>
          <div className={"map-component__logos"}>
            <img
              className={"map-component__logo"}
              alt={"Delivery Club"}
              src={DeliveryLogo}
            />
            <p className={"map-component__logo-cross"}>×</p>

            <img
              className={"map-component__logo map-component__logo-strelka"}
              alt={"Strelka KB"}
              src={StrelkaLogo}
              style={{ height: "100%" }}
            />
          </div>
          <img
            className={"map-component__legend"}
            src={currentLegend}
            alt={"Легенда"}
          />
        </div>
      </div>
    </section>
  )
}
