import { citiesData } from "../data/citiesData"
import { useState, useEffect } from "react"
import ExpandIcon from "../images/icons/expand.svg"
import { useMediaQuery } from "react-responsive"

export default function TableComponent({ level }) {
  const [mobile, setMobile] = useState(false)
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` })
  const [descIsOpened, setDescIsOpened] = useState(false)
  const [currentCity, setCurrentCity] = useState(undefined)

  useEffect(() => {
    if (isMobile) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }, [isMobile])

  const citiesSorted = citiesData.sort((el1, el2) => {
    if (el1.resultScore > el2.resultScore) {
      return -1
    }
    if (el1.resultScore < el2.resultScore) {
      return 1
    }
    return 0
  })
  const currentCities = citiesSorted.filter((el) => {
    if (level === "high") {
      return el.resultScore > 6.2
    }
    if (level === "average") {
      return el.resultScore <= 6.2 && el.resultScore > 5
    }
    if (level === "low") {
      return el.resultScore <= 5
    }
    return undefined
  })

  const clickHandling = (city) => {
    if (descIsOpened && city === currentCity) {
      setDescIsOpened(false)
      setCurrentCity(undefined)
    } else if (descIsOpened && city !== currentCity) {
      setDescIsOpened(false)
      setCurrentCity(city)
      setDescIsOpened(true)
    } else {
      setDescIsOpened(true)
      setCurrentCity(city)
    }
  }

  const handleIconClick = (evt, city) => {
    /*
      if (!mobile) {
        clickHandling(city)
      } else {
        if (evt.target.classList.contains("table__icon")) {
          clickHandling(city)
        }
      }
    */

    clickHandling(city)
  }

  return (
    <div className={`table-component table-component_${level}`}>
      <div className={"table__container"}>
        <h4 className={`headline_4`}>
          {level === "high"
            ? "Высокий уровень"
            : level === "average"
            ? "Средний уровень "
            : level === "low"
            ? "Низкий уровень"
            : ""}
        </h4>
        <p className={`paragraph_2`}>
          {level === "high"
            ? "Это топ-5 городов, где есть велоинфраструктура в популярных местах. " +
              "Они ближе всех к тому, чтобы по их улицам велосипедисты могли ездить с комфортом и без лишних рисков."
            : level === "average"
            ? "У этих городов есть ярко выраженные проблемные зоны. Основные причины две — неудобные и опасные для " +
              "велосипедистов участки и отсутствие или неоптимальное расположение велоинфраструктуры."
            : level === "low"
            ? "Эти города показали самые низкие результаты: их велоинфраструктура в меньшей степени, чем в других городах, " +
              "подходит для комфортных и безопасных поездок. Им нужны новые подходы и масштабные решения."
            : ""}
        </p>
        <div className={"table__mobile-info"}>
          <div className={"table__info-item"} style={{ gridArea: "info1" }}>
            <p
              className={"table__info-num"}
              style={{
                color:
                  level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : "",
              }}
            >
              1
            </p>
            <p className={"table__info-text"} style={{ fontWeight: "bold" }}>
              Результат рейтинга
            </p>
          </div>
          <div className={"table__info-item"} style={{ gridArea: "info2" }}>
            <p
              className={"table__info-num"}
              style={{
                color:
                  level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : "",
              }}
            >
              2
            </p>
            <p className={"table__info-text"}>
              Протяженность и расположение велодорожек
            </p>
          </div>
          <div className={"table__info-item"} style={{ gridArea: "info3" }}>
            <p
              className={"table__info-num"}
              style={{
                color:
                  level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : "",
              }}
            >
              3
            </p>
            <p className={"table__info-text"}>Удобство города для велосипеда</p>
          </div>
          <div className={"table__info-item"} style={{ gridArea: "info4" }}>
            <p
              className={"table__info-num"}
              style={{
                color:
                  level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : "",
              }}
            >
              4
            </p>
            <p className={"table__info-text"}>Безопасность передвижения</p>
          </div>
          <div className={"table__info-item"} style={{ gridArea: "info5" }}>
            <p
              className={"table__info-num"}
              style={{
                color:
                  level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : "",
              }}
            >
              5
            </p>
            <p className={"table__info-text"}>Вывод для города</p>
          </div>
        </div>
        <div className={"table"}>
          <div className={"table__row table__row_head"}>
            <p className={"table__cell table__cell_city"}>Город</p>
            <p
              className={"table__cell table__cell_bold"}
              style={{
                color: mobile
                  ? level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : ""
                  : "white",
              }}
            >
              {mobile ? "1" : "Результат рейтинга"}
            </p>
            <p
              className={"table__cell"}
              style={{
                color: mobile
                  ? level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : ""
                  : "white",
              }}
            >
              {mobile ? "2" : "Протяженность и расположение велодорожек"}
            </p>
            <p
              className={"table__cell"}
              style={{
                color: mobile
                  ? level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : ""
                  : "white",
              }}
            >
              {mobile ? "3" : "Удобство города для велосипеда"}
            </p>
            <p
              className={"table__cell"}
              style={{
                color: mobile
                  ? level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : ""
                  : "white",
              }}
            >
              {mobile ? "4" : "Безопасность передвижения"}
            </p>
            <p
              className={"table__cell"}
              style={{
                color: mobile
                  ? level === "high"
                    ? "#0CC744"
                    : level === "average"
                    ? "#F3AF49"
                    : level === "low"
                    ? "#EB4A6A"
                    : ""
                  : "white",
              }}
            >
              {mobile ? "5" : "вывод для города"}
            </p>
          </div>
          {currentCities && currentCities.length > 0 ? (
            currentCities.map((city, i) => {
              return (
                <div
                  className={"table__item"}
                  key={"table_" + city.id ? city.id : i}
                >
                  <div
                    className={`table__row ${
                      city.id === currentCity?.id ? "table__row_selected" : ""
                    }`}
                    onClick={(evt) => handleIconClick(evt, city)}
                  >
                    <p
                      className={`table__cell table__cell_city ${
                        i + 1 === currentCities.length ? "table__cell_last" : ""
                      }`}
                      style={{ textTransform: "uppercase" }}
                    >
                      {city.title}
                    </p>
                    <p
                      className={`table__cell table__cell_bold ${
                        i + 1 === currentCities.length ? "table__cell_last" : ""
                      }`}
                    >
                      {city.resultScore}
                    </p>
                    <p
                      className={`table__cell  ${
                        i + 1 === currentCities.length ? "table__cell_last" : ""
                      }`}
                    >
                      {city.relevanceScore}
                    </p>
                    <p
                      className={`table__cell  ${
                        i + 1 === currentCities.length ? "table__cell_last" : ""
                      }`}
                    >
                      {city.convenienceScore}
                    </p>
                    <p
                      className={`table__cell  ${
                        i + 1 === currentCities.length ? "table__cell_last" : ""
                      }`}
                    >
                      {city.safetyScore}
                    </p>
                    <div
                      className={`table__cell  ${
                        i + 1 === currentCities.length ? "table__cell_last" : ""
                      }`}
                    >
                      <img
                        className={`table__icon ${
                          descIsOpened && currentCity.id === city.id
                            ? "table__icon_opened"
                            : ""
                        }`}
                        alt={"Развернуть"}
                        src={ExpandIcon}
                      />
                    </div>
                  </div>
                  <div
                    className={`table__desc ${
                      descIsOpened && currentCity.id === city.id
                        ? "table__desc_opened"
                        : ""
                    }`}
                  >
                    <p className={"paragraph_2"}>{city.desc}</p>
                  </div>
                </div>
              )
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}
