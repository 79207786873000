import TableComponent from "./TableComponent"
import { Link } from "react-router-dom"
import DotIcon from "../images/icons/dot.svg"

export default function ReadyBlock() {
  return (
    <section className={"main__section"} id={"ready"}>
      <h2 className={"headline_2"}>
        Насколько наши города удобны для велосипедистов
      </h2>
      <div className={"ready"}>
        <p className={"paragraph_2"}>
          Рейтинг удобства городов для велосипедистов — первый подобный рейтинг
          в России. Он одновременно учитывает перемещения всех участников
          велодвижения и расположение существующей велоинфраструктуры.
          <br />
          <br />
          Рейтинг был рассчитан с помощью шести индикаторов:
        </p>

        <div className={"ready__grid"}>
          <div className={"ready__card"}>
            <h3 className={"headline_4 ready__card-title"}>
              Протяженность и&nbsp;расположение велодорожек в городе
            </h3>
            <p className={"paragraph_2 ready__card-paragraph"}>
              <img className={"paragraph__dot"} alt={"1"} src={DotIcon} />{" "}
              Наличие на улицах велодорожек и велополос
            </p>
            <p className={"paragraph_2 ready__card-paragraph"}>
              <img className={"paragraph__dot"} alt={"2"} src={DotIcon} />{" "}
              Актуальность расположения велодорожек и велополос
            </p>
          </div>
          <div className={"ready__card"}>
            <h3 className={"headline_4 ready__card-title"}>
              Удобство города для велосипеда
            </h3>
            <p className={"paragraph_2 ready__card-paragraph"}>
              <img className={"paragraph__dot"} alt={"3"} src={DotIcon} />{" "}
              Соотношение маршрутов в&nbsp;объезд и кратчайших возможных
              маршрутов велосипедистов
            </p>
            <p className={"paragraph_2 ready__card-paragraph"}>
              <img className={"paragraph__dot"} alt={"4"} src={DotIcon} />{" "}
              Максимальная протяженность веломаршрута по&nbsp;велодорожкам и
              велополосам
            </p>
          </div>
          <div className={"ready__card"}>
            <h3 className={"headline_4 ready__card-title"}>
              Безопасность передвижения на&nbsp;велосипеде
            </h3>
            <p className={"paragraph_2 ready__card-paragraph"}>
              <img className={"paragraph__dot"} alt={"5"} src={DotIcon} />{" "}
              Количество ДТП с&nbsp;велосипедами и автомобилями на&nbsp;100 км
              улиц
            </p>
            <p className={"paragraph_2 ready__card-paragraph"}>
              <img className={"paragraph__dot"} alt={"6"} src={DotIcon} /> Доля
              ДТП с велосипедами и&nbsp;автомобилями с летальным исходом
            </p>
          </div>
        </div>
        <Link
          className={""}
          to={"/methodology"}
          style={{ width: "fit-content" }}
        >
          <p className={"ready__link"}>Подробнее об индикаторах рейтинга</p>
        </Link>
      </div>

      <TableComponent level={"high"} />
      <TableComponent level={"average"} />
      <TableComponent level={"low"} />
    </section>
  )
}
