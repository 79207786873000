import VeloRoad from "../images/choices/veloroad.jpg"
import SilentStreets from "../images/choices/silentStreets.jpg"
import Banks from "../images/choices/banks.jpg"
import Boulevards from "../images/choices/boulevards.jpg"
/*

import CrowdedStreets from "../images/choices/crowdedStreets.jpg"
import Crossroads from "../images/choices/crossroads.jpg"
import Stairways from "../images/choices/stairways.jpg"
import Fences from "../images/choices/fences.jpg"

 */

export default function CyclistsChoose() {
  return (
    <section className={"main__section"}>
      <div className={"choice__container"}>
        <p className={"choice__title"}>Велосипедисты выбирают</p>
        <div className={"choice__items-container"}>
          <ul className={"choice__items"}>
            <li className={"choice__item"}>
              <img
                className={"choice__image"}
                alt={"Велодорожки и велополосы"}
                src={VeloRoad}
              />
              <p className={"choice__text"}>Велодорожки и велополосы</p>
            </li>
            <li className={"choice__item"}>
              <img
                className={"choice__image"}
                alt={"Тихие улицы со спокойным трафиком"}
                src={SilentStreets}
              />
              <p className={"choice__text"}>
                Тихие улицы со спокойным трафиком
              </p>
            </li>
            <li className={"choice__item"}>
              <img className={"choice__image"} alt={"Набережные"} src={Banks} />
              <p className={"choice__text"}>Набережные</p>
            </li>
            <li className={"choice__item"}>
              <img
                className={"choice__image"}
                alt={"Бульвары, аллеи, парки"}
                src={Boulevards}
              />
              <p className={"choice__text"}>Бульвары, аллеи, парки</p>
            </li>
          </ul>
        </div>
      </div>
      {/*
        <div className={"choice__container"}>
          <p className={"choice__title"}>Велосипедисты избегают</p>
          <div className={"choice__items-container"}>
            <ul className={"choice__items"}>
              <li className={"choice__item"}>
                <img
                  className={"choice__image"}
                  alt={
                    "Улицы с высокими автомобильными и пешеходными потоками без велодорожек"
                  }
                  src={CrowdedStreets}
                />
                <p className={"choice__text"}>
                  Улицы с высокими автомобильными и пешеходными потоками без
                  велодорожек
                </p>
              </li>
              <li className={"choice__item"}>
                <img
                  className={"choice__image"}
                  alt={"Сложные перекрестки"}
                  src={Crossroads}
                />
                <p className={"choice__text"}>Сложные перекрестки </p>
              </li>
              <li className={"choice__item"}>
                <img
                  className={"choice__image"}
                  alt={"Подземные и надземных переходы, лестницы без пандусов"}
                  src={Stairways}
                />
                <p className={"choice__text"}>
                  Подземные и надземные переходы, лестницы без пандусов
                </p>
              </li>
              <li className={"choice__item"}>
                <img
                  className={"choice__image"}
                  alt={"Улицы со сплошными ограждениями и высокими бордюрами"}
                  src={Fences}
                />
                <p className={"choice__text"}>
                  Улицы со сплошными ограждениями и высокими бордюрами
                </p>
              </li>
            </ul>
          </div>
        </div>

      <p className={"paragraph_1"}>
        Самые опасные для велосипедистов места — перекрестки и пешеходные
        переходы, точки пересечения с другими участниками движения. На них
        приходится 45% всех ДТП с велосипедистами (часть происшествий происходит
        на пешеходных переходах, расположенных на перекрестках). При этом
        инциденты происходят не только во время езды, но и в точках начала и
        окончания поездки, в пространствах с низкой скоростью передвижения. На
        них приходится больше 30% случаев.
      </p>*/}
    </section>
  )
}
