import { useState, useEffect, useCallback } from "react"
import Menu from "../images/icons/menu.svg"
import Close from "../images/icons/cross.svg"
import Footer from "./Footer"
import DeliveryLogo from "../images/logos/Delivery_logo.svg"
import StrelkaLogo from "../images/logos/kb-logo.svg"
import Divider from "../images/icons/share/divider.svg"

import {
  FbShareButton,
  TelegramShareButton,
  TwitterShareButton,
  VkShareButton,
} from "./ShareButtons"
import { useMediaQuery } from "react-responsive"

const Navbar = ({ menuInitial }) => {
  const [navbarIsFolded, setNavbarIsFolded] = useState(true)
  const [menuState, setMenuState] = useState(true)
  const [yPos, setYPos] = useState(window.scrollY)
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` })

  const handleMenuClick = () => {
    setNavbarIsFolded(!navbarIsFolded)
  }
  useEffect(() => {
    if (document.querySelector("body")) {
      if (!navbarIsFolded) {
        document.querySelector("body").classList.add("block-scroll")
      }
      if (navbarIsFolded) {
        document.querySelector("body").classList.remove("block-scroll")
      }
    }
  }, [navbarIsFolded])

  const handleNotMenuClick = (evt) => {
    if (evt.target.classList.contains("footer__link")) {
      handleMenuClick()
    }
  }

  useEffect(() => {
    if (!navbarIsFolded) {
      window.addEventListener("click", handleNotMenuClick)
      return () => {
        window.removeEventListener("click", handleNotMenuClick)
      }
    }
    // eslint-disable-next-line
  }, [navbarIsFolded])

  const hideMenu = () => {
    setMenuState(false)
  }

  const openMenu = () => {
    setMenuState(true)
  }

  const handleScroll = useCallback(
    (e) => {
      const window = e.currentTarget
      if (window.scrollY > 0) {
        if (yPos > window.scrollY) {
          openMenu()
        } else if (yPos < window.scrollY) {
          hideMenu()
        }
        setYPos(window.scrollY)
      } else {
        openMenu()
      }
    },
    [yPos]
  )

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])

  return (
    <>
      <div id="fb-root" />
      <div className={`menu ${menuState ? "" : "menu_hidden"}`}>
        <div className={"menu__container"}>
          <div
            className={`title__logos title__logos_desktop ${
              navbarIsFolded || !isMobile ? "" : "menu-item_hidden"
            }`}
          >
            <a
              href={"https://www.delivery-club.ru/"}
              rel="noreferrer"
              target={"_blank"}
            >
              <img
                className={"title__logo"}
                alt={"Delivery Club"}
                src={DeliveryLogo}
              />
            </a>
            <p className={"title__logo-cross"}>×</p>
            <a
              href={"https://strelka-kb.com/"}
              rel="noreferrer"
              target={"_blank"}
            >
              <img
                className={"title__logo"}
                alt={"Strelka KB"}
                src={StrelkaLogo}
                style={{ height: "100%" }}
              />
            </a>
          </div>
          <div
            className={`menu__share ${
              navbarIsFolded || !isMobile ? "menu-item_hidden" : ""
            }`}
          >
            <FbShareButton />
            <VkShareButton />
            <TelegramShareButton />
            <TwitterShareButton />
          </div>
          <div className={"navbar__share-and-button"}>
            <div className={"navbar__share"}>
              <FbShareButton />
              <VkShareButton />
              <TelegramShareButton />
              <TwitterShareButton />
            </div>
            <img
              className={"navbar__share-divider"}
              alt={"divider"}
              src={Divider}
            />
            <img
              className={"navbar__button"}
              alt={navbarIsFolded ? "Закрыть меню" : "Открыть меню"}
              src={navbarIsFolded ? Menu : Close}
              onClick={handleMenuClick}
            />
          </div>
        </div>
      </div>
      <nav className={`navbar ${navbarIsFolded ? "" : "navbar_active"}`}>
        <Footer isFooter={false} />
      </nav>
    </>
  )
}

export default Navbar
