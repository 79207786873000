import { Link } from "react-router-dom"
import DeliveryLogo from "../images/logos/Delivery_logo.svg"
import StrelkaLogo from "../images/logos/kb-logo.svg"

export default function Footer({ isFooter = true }) {
  const FooterLogos = () => {
    return (
      <div className={"footer__logos"}>
        <a
          href={"https://www.delivery-club.ru/"}
          rel="noreferrer"
          target={"_blank"}
        >
          <img
            className={"title__logo"}
            alt={"Delivery Club"}
            src={DeliveryLogo}
          />
        </a>
        <p className={"title__logo-cross"}>×</p>
        <a href={"https://strelka-kb.com/"} rel="noreferrer" target={"_blank"}>
          <img
            className={"title__logo title__logo_strelka"}
            alt={"Strelka KB"}
            src={StrelkaLogo}
            style={{ height: "100%" }}
          />
        </a>
      </div>
    )
  }
  const MenuContainer = () => {
    return (
      <>
        <div className="footer__container">
          <div className={"footer__title-container"}>
            {isFooter ? <FooterLogos /> : <></>}
            <h3 className="footer__title">ТРАНСПОРТ БУДУЩЕГО:</h3>
            <h4 className="footer__subtitle">
              как сделать города удобнее для велосипедистов
            </h4>
          </div>
          <nav className="footer__nav">
            <div className="footer__links-container">
              <ul className="footer__links">
                <li className="footer__link">
                  <a className="footer__link" href={"../#why-velo"}>
                    Как велосипедисты помогают городам
                  </a>
                </li>
                <li className="footer__link">
                  <a className="footer__link" href={"../#ready"}>
                    Насколько наши города удобны для велосипедистов
                  </a>
                </li>
                <li className="footer__link">
                  <a className="footer__link" href={"../#conclusions"}>
                    4 главных вывода исследования
                  </a>
                </li>
                <li className="footer__link">
                  <a className="footer__link" href={"../#advices"}>
                    Как развивать велоинфраструктуру своего города
                  </a>
                </li>
                <li className="footer__link">
                  {
                    <Link className="footer__link" to={"/methodology"}>
                      Методика и данные
                    </Link>
                  }
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="footer__made-by">
          <div className="footer__contacts">
            <p className="footer__contacts-title">Связаться с нами: </p>
            <div className="footer__contact">
              <p className="footer__contact-text">
                Пресс-cлужба КБ Стрелка —{" "}
                <a
                  href="mailto:pr@strelka-kb.com"
                  className="footer__contact-link"
                >
                  pr@strelka-kb.com
                </a>
              </p>
              <p className="footer__contact-text">
                Пресс-служба Delivery Club —{" "}
                <a
                  href="mailto:press@delivery-club.ru"
                  className="footer__contact-link"
                >
                  press@delivery-club.ru
                </a>
              </p>
            </div>
            <p className="footer__made-by-text">© ООО «КБ Стрелка», 2021</p>
          </div>
        </div>
      </>
    )
  }
  if (isFooter) {
    return (
      <footer className="footer">
        <MenuContainer />
      </footer>
    )
  } else {
    return (
      <div className={"footer"}>
        <MenuContainer />
      </div>
    )
  }
}
